import React from 'react';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'util/constants';
import * as styles from 'styles/pages/dj-signup.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Catalün Village DJ Signup</title>
    </PageHead>
  );
}

function DjSignupPage() {
  return (
    <Page className={styles.DjSignup}>
      <section className={styles.pageCenter}>
        <h2 className={styles.smallHeading}>Catalün DJ Signup</h2>
        <p className={styles.subtext}>
          <span>Request to play music at Catalun Village.</span>
        </p>
        <iframe
          title="DJ Signup Google Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdPbx99fqnDRMj52Ty3u3JdmTGTv1ZBxfJyZ0s5nf1U76-20A/viewform?embedded=true"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
        >
          Loading…
        </iframe>
      </section>
    </Page>
  );
}

export default DjSignupPage;
